import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import plug from './assets/js/config.js' //导入插件
Vue.prototype.plug = plug //定义自定义插件
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
// import './util/flexible'
// import 'adaptive.js/js/adaptive.js'

// 引入echarts
// var echarts = require('echarts');
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts

// 引入jquery
import jquery from 'jquery'
window.jquery = window.$ = jquery

// 引入swiper
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

Vue.config.productionTip = false

// 路由拦截
router.beforeEach((to, from, next) => {
  if(to.meta.loginRequest)  { //如果路由meta重的loginRequest为true，进行拦截
    if(window.localStorage.getItem("screenUserInfo")){
      next(); //如果已登录可直接进入页面
    }else{  // 否则转至登录页并及主要跳转的页面以便登录完成后直接进入该页面
      next({
        path:'/',
        query:{
          redirect: to.fullPath //把要跳转的页面路径作为参数传到登录页
        }
      })
    }
  }else{
    next(); //直接进入页面
  }
})

new Vue({
  $: "jquery",
  jQuery: "jquery",
  router,
  store,
  render: h => h(App)
}).$mount('#app')
