window.PageTitle = "绍兴市智慧化数字体育平台";
// window.host = "https://apis.zjzxsl.com/";
// window.appHost = "https://sxty.zjzxsl.com/";

window.host = "https://yyd-apis.zjzxsl.com/";
window.appHost = "https://yyd.zjzxsl.com/";
window.socketHost = "wss://yyd-apis.zjzxsl.com:8283";

window.appId = '1002';
window.time = Date.parse(new Date()) / 1000;
window.key = 'zxsl';
window.messageList = {};

import bus from './bus.js'
import store from '../../store/index'
// window.ws = new WebSocket(window.socketHost);
// 	window.ws.onopen = function(e) {
// 		console.log("socket 连接成功！")
// 		// setInterval(function() {
// 		// 	window.ws.send('ping');
// 		// }, 3000)
// 	};

/**
 * 初始化屏幕自适应
 */
window.windowResize = () => {
	var width = $(window).width()

	// $("#main-shadow").css({"color":"red"}).html(
	// 	'width：'+$(window).width()+'\n\r'+
	// 	'height：'+$(window).height()+'\n\r'+
	// 	"scrollWidth："+document.body.scrollWidth+'\n\r'+
	// 	"scrollHeight："+document.body.scrollHeight+'\n\r'+
	// 	"screenWidth："+window.screen.width+'\n\r'+
	// 	"screenHeight："+window.screen.height+'\n\r'
	// )
	// console.log(window.screen.width)
	$("html").css({fontSize: width / 19.2})
	$(window).resize(function() {
		var width = $(window).width()
		$("html").css({fontSize: width / 19.2})
	});
	
}

window.setCookies = (key,val) => {
	$.cookie(key,val);
}
window.getCookies = (key) => {
	return $.cookie(key);
}
window.echartsBtnClick = (event) => {
	let className = event.target.className;
	if (className == "window-echarts-open-btn") {
		event.target.className = "window-echarts-close-btn"
		$("#shaoxing").fadeOut();
		$(".posit-left").fadeOut();
		$(".posit-right").fadeOut();
		$(".event-top").fadeOut();
		$(".event-bottom").fadeOut();
		$(".people").fadeOut();
		$(".swiper-container").fadeOut();
		$(".national").fadeOut();
	} else {
		event.target.className = "window-echarts-open-btn"
		$("#shaoxing").fadeIn();
		$(".posit-left").fadeIn();
		$(".posit-right").fadeIn();
		$(".event-top").fadeIn();
		$(".event-bottom").fadeIn();
		$(".people").fadeIn();
		$(".swiper-container").fadeIn();
		$(".national").fadeIn();
	}
}

function getToken(appId, key) {
	return (md5(md5(`app_id=${appId}&key=` + key) + appId + key)).toUpperCase();
}

window.getToken = () => {
	return (md5(md5(`app_id=${window.appId}&key=` + window.key) + window.appId + window.key)).toUpperCase();
}

window.getData = (actionName,jsonData) => {
	var postUrl = window.host + "map/index/getData"
	return ajaxRequest(actionName, jsonData,postUrl,'get');
	// getSocket(actionName, 1)
}

window.setData = (actionName, jsonData) => {
	var postUrl = window.host + "map/index/setData"
	// var postUrl = window.host + "map/index/getData"
	return ajaxRequest(actionName, jsonData, postUrl)
	
}


function getSocket(actionName, mid) {
	var sendData = JSON.stringify({
		"action" : actionName,
		"m_id" : 1
	});
	// setInterval(function() {
		window.ws.send(sendData);
	// }, 3000)
		
	
	window.ws.onmessage = function(e) {
		let res = JSON.parse(e.data);
		var message = '';
		if(res.result.msg.search(actionName) != -1)
		{
			// window.messageList.$data
			// console.log(333, parseInt(res.data))
			// console.log(1111, window.messageList.$data)


			
			// window.messageList.$data.number.people =  parseInt(res.data);
		}
	}
	// 断开连接
	window.ws.onclose = function(e){
		window.ws.close()
	}
}


window.ajaxRequest =  (postUrl, jsonData={}, type='get', header='') => {
	var returnValue;
	var getTokenValue = getToken(window.appId, window.key);
	$.ajax({
		header:header,
		dataType:"json",
		type: type,
		url: postUrl,
		data: jsonData,
		async: false, //异步请求
		success: (res) => {
			// console.log(res)
			// var res = JSON.parse(res);
			// var code = res.result.code
			// var msg = res.result.msg
			// if (code == "200") {
			// 	//console.log('--------------------', res.data.value)
			// 	returnValue = res.data.value
			// } else {
			// 	alert(msg);
			// }
			returnValue = res;
		},
		error: (e) => {
			// alert("请求异常或网络错误")
			console.log("请求异常或网络错误", e)
		}
	})

	return returnValue;
}


function ajaxRequest(actionName, jsonData, postUrl,type) {
	var returnValue;
	var getTokenValue = getToken(window.appId, window.key);
	$.ajax({
		type: type,
		url: postUrl,
		data: {
			token: getTokenValue,
			app_id: window.appId,
			time: window.time, //Date.parse(new Date()) / 1000,
			name: actionName,
			value: JSON.stringify(jsonData)
		},
		async: false, //异步请求
		success: (res) => {
			// console.log(res)
			var res = JSON.parse(res);
			var code = res.result.code
			var msg = res.result.msg
			if (code == "200") {
				//console.log('--------------------', res.data.value)
				returnValue = res.data.value
			} else {
				alert(msg);
			}
		},
		error: (e) => {
			alert("请求异常或网络错误")
			console.log("请求异常或网络错误", e)
		}
	})

	return returnValue;
}


/**
 * 初始化屏幕时间
 */
window.showDate = () => {
	var date = new Date();
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();

	var dateDay;

	switch (date.getDay()) {
		case 0:
			dateDay = "星期日";
			break;
		case 1:
			dateDay = "星期一";
			break;
		case 2:
			dateDay = "星期二";
			break;
		case 3:
			dateDay = "星期三";
			break;
		case 4:
			dateDay = "星期四";
			break;
		case 5:
			dateDay = "星期五";
			break;
		case 6:
			dateDay = "星期六";
			break;
	}

	if (date.getHours().toString().length < 2) {
		var dateHours = '0' + date.getHours() // 获取小时
	} else {
		var dateHours = date.getHours()
	}
	if (date.getMinutes().toString().length < 2) {
		var dateMinutes = '0' + date.getMinutes() // 获取分钟
	} else {
		var dateMinutes = date.getMinutes()
	}
	if (date.getSeconds().toString().length < 2) {
		var dateSeconds = '0' + date.getSeconds() // 获取秒
	} else {
		var dateSeconds = date.getSeconds()
	}
	return {
		titleDate: year + '年' + month + '月' + day + '日',
		titleWeek: dateDay,
		titleTime: dateHours + ":" + dateMinutes + ":" + dateSeconds,
	};
}

window.fullScreenClick = (event) => {
	let className = event.target.className;
	if (className == "window-big-btn open") {
		event.target.className = "window-big-btn close"
		fullScreen()
	} else {
		event.target.className = "window-big-btn open"
		exitFullScreen()
	}
}

window.initLoading = () => {
	//在页面未加载完毕之前显示的loading Html自定义内容
	var _LoadingHtml = `<div class="loader" id="loadingDiv">
		<div class="loader-inner">
			<div class="loader-line-wrap">
				<div class="loader-line"></div>
			</div>
			<div class="loader-line-wrap">
				<div class="loader-line"></div>
			</div>
			<div class="loader-line-wrap">
				<div class="loader-line"></div>
			</div>
			<div class="loader-line-wrap">
				<div class="loader-line"></div>
			</div>
			<div class="loader-line-wrap">
				<div class="loader-line"></div>
			</div>
		</div>
	</div>`; //呈现loading效果
	document.write(_LoadingHtml);
	//监听加载状态改变
	document.onreadystatechange = completeLoading;
}

//加载状态为complete时移除loading效果
function completeLoading() {
	if (document.readyState == "complete") {
		var loadingMask = document.getElementById('loadingDiv');
		setTimeout(() => {
			loadingMask.parentNode.removeChild(loadingMask);
		}, 2000);
	}
}

// 全屏代码
// function fullScreen() {
// 	var elem = document.body;
// 	if (elem.webkitRequestFullScreen) {
// 		elem.webkitRequestFullScreen();
// 	} else if (elem.mozRequestFullScreen) {
// 		elem.mozRequestFullScreen();
// 	} else if (elem.requestFullScreen) {
// 		elem.requestFullscreen();
// 	} else {
// 		notice.notice_show("浏览器不支持全屏API或已被禁用", null, null, null, true, true);
// 	}
// }
function fullScreen(){
	var el = document.documentElement; 
	var rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen; 
	 
	if (typeof rfs != "undefined" && rfs) { 
		rfs.call(el); 
	} else if (typeof window.ActiveXObject != "undefined") { 
		// for Internet Explorer 
		var wscript = new ActiveXObject("WScript.Shell"); 
		if (wscript != null) { 
		wscript.SendKeys("{F11}"); 
	} 
	} 
}

function exitFullScreen() {
	var elem = document;
	if (elem.webkitCancelFullScreen) {
		elem.webkitCancelFullScreen();
	} else if (elem.mozCancelFullScreen) {
		elem.mozCancelFullScreen();
	} else if (elem.cancelFullScreen) {
		elem.cancelFullScreen();
	} else if (elem.exitFullscreen) {
		elem.exitFullscreen();
	} else {
		notice.notice_show("浏览器不支持全屏API或已被禁用", null, null, null, true, true);
	}
}
// 声明插件 
export default {
	upDatavenues:function(params) {
		let markerImg = params.data.markerImg
		// 判断是否需要清空点位数据
		if (params.data.clear) {
			window.map.clearMap()
		}
		if (params.data.add) {
			var path = params.data.points
			var markers
			if(window.screen.width == 960){
				window.lotteryjson.gymnasium.data.zoom=17;
			}
			path.forEach(function(res, k) {
				var html;
				if (res.name == "越城区体育中心") {
					html = 'width:297px;height:283px;'
				} else if (res.name == "棒垒球体育文化中心") {
					html = 'width:155px;height:113px;'
				}
	
				markers = new AMap.Marker({
					position: res.location,
					offset: new AMap.Pixel(0, -70),
					zooms: params.data.zooms, // 设置层级范围 显示3D 绍兴区域
					content: `<div>
					<span class='jiedao-marker'><img class='markerlnglat' src='${markerImg}'>${res.name}</span>
					</div>`
				});
				window.map.add(markers)
				// if (res.name == "越城区体育中心") { // 给模型添加点击事件
					markers.on('click', function() {
						// window.communityname = res.name
						window.venueName = res.name;
						// window.echartsShow('stadiumtwo', window.thisShow)
						bus.$emit("venuesName",res.name)
						// window.thisShow.plug.upDataMap(window.lotteryjson.stadiumtwo)
					});
			})
		}	
		window.map.setZoomAndCenter(params.data.zoom, params.data.center)
	},

	upDataMap: function(params) {
		console.log(params)
		var markerImg = params.data.markerImg
		var street = []; // 用于存储街道
		var downtown = []; // 用于存储市区
		// 判断是否需要清空点位数据
		if (params.data.clear) {
			window.map.clearMap()
		}
		//判断是否需要添加点位
		if (params.data.add) {
			var path = params.data.points
			var siteArea = params.data.siteArea
			var markers

			if (params.data.contentType == 0) {
				path.forEach(function(res) {
					// if(type == 1) markerImg = './images/lottery.png';
					// else markerImg = './images/enterprise.png';
					// markerImg = res.type == 1 ? './images/lottery.png' : './images/enterprise.png';
					markers = new AMap.Marker({
						position: res.location,
						offset: new AMap.Pixel(-13, -30),
						zooms: [10, 20], // 设置层级范围 显示3D 绍兴区域
						content: `<div>,
						<img class='markerlnglat' src='${res.img}'>
						<span class='jiedao-marker'>${res.name}<span>
						</div>`
					});
					window.map.add(markers)
				})
			} else if (params.data.contentType == 1) {
				path.forEach(function(res) {
					let area = ''
					if (siteArea) {
						area = siteArea[res.name]
					}
					
					markers = new AMap.Marker({
						position: res.location,
						offset: new AMap.Pixel(-13, -30),
						zooms: params.data.zooms, // 设置层级范围 显示3D 绍兴区域
						content: `<div>
						<img class='markerlnglat' src='${markerImg}' style='top: -35px;'/>
						<img class='markerlnglat' src='${params.data.markerbottomImg}' style="position:relative;top:-57px;"/>
						<img class='marker-bg' src='${params.data.markerBg}'/>
						<span class='marker1'>${res.name}</span>
						<span class='marker2'>人均体育场地面积：</span>
						<span class='marker-label'>${area}m²</span>
						</div>`
					});
					window.map.add(markers)
					downtown.push({
						title:res.name,
						location:res.location,
						id:res.id
					}) // 市区

					window.downtown = downtown
					
					// if (res.name == "越城区") {
					markers.on('click', function() {
						console.log(res);
						window.communityname = res.name;
						window.new_area_id = res.id;
						window.echartsShow('street', window.thisShow)
						window.thisShow.plug.upDataMap(window.lotteryjson.yuecheng)
						const typeObj = {
							'市本级': 0,
							'越城区': 1,
							'柯桥区': 2,
							'上虞区': 3,
							'诸暨市': 4,
							'嵊州市': 5,
							'新昌县': 6
						}
						
						setTimeout(() => {
							bus.$emit('area_id',res.id)
						}, 200);
						let area = {
							area_id: res.id,
							area_type: typeObj[res.name]
						}
						store.commit('area', area);
					});
					// }
				})
			} else if (params.data.contentType == 2) {
				path.forEach(function(res) {
					// console.log(res.location)
					markers = new AMap.Marker({
						position: res.location,
						offset: new AMap.Pixel(-13, -30),
						zooms: params.data.zooms, // 设置层级范围 显示3D 绍兴区域
						content: `<div>
						<span class='jiedao-marker'><img class='markerlnglat' src='${markerImg}'>${res.name}</span>
						</div>`
					});
					window.map.add(markers)
					// console.log(2222, res.name)

					markers.on('click', function() { // 百姓健身房
						// window.communityname = res.name
						window.jianshenfangData = res;
						window.echartsShow('peoplethree', window.thisShow)
						$('#amap').hide()
					});


				})
			} else if (params.data.contentType == 3) {
				if(window.screen.width == 960){
					window.lotteryjson.gymnasium.data.zoom=17;
				}
				path.forEach(function(res, k) {
					var html;
					if (res.name == "越城区体育中心") {
						html = 'width:297px;height:283px;'
					} else if (res.name == "棒垒球体育文化中心") {
						html = 'width:155px;height:113px;'
					}

					markers = new AMap.Marker({
						position: res.location,
						offset: new AMap.Pixel(0, -70),
						zooms: params.data.zooms, // 设置层级范围 显示3D 绍兴区域
						content: `<div>
						<span class='jiedao-marker'><img class='markerlnglat' src='${markerImg}'>${res.name}</span>
						</div>`
					});
					window.map.add(markers)
					// if (res.name == "越城区体育中心") { // 给模型添加点击事件
						markers.on('click', function() {
							// window.communityname = res.name
							window.venueName = res.name;
							window.echartsShow('stadiumtwo', window.thisShow)
							// window.thisShow.plug.upDataMap(window.lotteryjson.stadiumtwo)
						});
					// } else if (res.name == "棒垒球体育文化中心") {
					// 	markers.on('click', function() {
					// 		// window.communityname = res.name
					// 		window.echartsShow('stadiumthree', window.thisShow)
					// 	});
					// }
				})
			} else if (params.data.contentType == 4) {
				var polyline = new AMap.Polyline({
					map: map,
					showDir: true,
					path: params.data.points,
					isOutline: true,
					outlineColor: '#1EF9F9',
					borderWeight: 1,
					strokeColor: "#1EF9F9",
					strokeOpacity: 1,
					strokeWeight: 1,
					// 折线样式还支持 'dashed'
					strokeStyle: "solid",
					// strokeStyle是dashed时有效
					strokeDasharray: [10, 5],
					lineJoin: 'round',
					lineCap: 'round',
					zIndex: 50,
				})

				window.map.add([polyline]);
			} else if (params.data.contentType == 5) {
				path.forEach(function(res) {
					markers = new AMap.Marker({
						position: res.location,
						offset: new AMap.Pixel(-13, -30),
						zooms: params.data.zooms, // 设置层级范围 显示3D 绍兴区域
						content: `<div>
						<span class='jiedao-marker'><img class='markerlnglat' src='${markerImg}'>${res.name}</span>
						</div>`
					});
					window.map.add(markers)
					street.push(res.name)
					window.completestreet = street //  全部街道信息

					markers.on('click', function() {
						window.echartsShow('streetone', window.thisShow, res.name)
						$('#amap').hide();
						window.clickstreet = res.name
						// console.log(222, res.name)
					});
				})
			} else {
				path.forEach(function(res) {
					markers = new AMap.Marker({
						position: res.location,
						offset: new AMap.Pixel(-13, -30),
						zooms: [10, 20], // 设置层级范围 显示3D 绍兴区域
						content: `<div>,
						<img class='markerlnglat' src='${markerImg}'>
						<span class='marker'>${res.name}<span>
						</div>`
					});
					window.map.add(markers)
				})
			}
			window.map.setZoomAndCenter(params.data.zoom, params.data.center)
			if(window.screen.width == 960){
				window.lotteryjson.taohuadao.data.zoom=15;
			}
		}
	}
}
