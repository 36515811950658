<template>
  <!-- 主页 -->
  <div class="font16" style="width: 100%">
    <!-- 加载高德地图 -->
    <!-- <div id="titless" style="color:red;font-size:12px;"></div> -->
    <!-- <div id="amap" style="width:99.8%;margin: 0 auto; text-align:center;border:0.01rem solid rgb(26, 186, 234);border-radius:0.2rem"></div>	 -->
    <!-- <div id="amap"></div>	 -->
    <!-- <div id="main-border"></div> -->
    <!-- <div id="main-shadow"></div> -->
    <!-- 顶部信息栏 -->
    <!-- <div class="top gray">
			<div id="homeTitle" class="font18">{{title}}</div>
		</div>
		<div class="appnav">
			<div class="appleft">
				<div class="window-echarts-open-btn" @click="windowEchartsBtnClick($event)">
					<div class="appnav4"></div>
				</div>
				<div class="appnav1 selected" @click="leftclick($event, 'shaoxingone')"><span>主页</span></div>
				<div class="appnav1" @click="leftclick($event, 'peopleone')"><span>百姓健身房</span></div>
				<div class="appnav1" @click="leftclick($event, 'stadiumone')"><span>体育场馆</span></div>
				<div class="appnav1" @click="leftclick($event, 'touristwalk')"><span>游步道</span></div>
			</div>
			<div class="appright">
				<div class="appnav2" @click="rightclick($event, 'familyfitness')"><span>云健身房</span></div>
				<div class="appnav2" @click="rightclick($event, 'eventsone')"><span>赛事</span></div>
				<div class="appnav2" @click="rightclick($event, 'sportindustry')"><span>体育产业</span></div>
				<div class="appnav2" @click="rightclick($event, 'association')"><span>体育协会</span></div>
				<div class="window-big-btn open" @click="fullScreenClick($event)">
					<div class="appnav4"></div>
				</div>
			</div>
		</div>
		<div class="time">
			<span>{{titleDate}}</span>
			<span>{{titleWeek}}</span>
			<span>{{titleTime}}</span>
		</div>
		<div>
			<shaoxingone v-if="$store.state.shaoxingone"></shaoxingone>
			<street v-if="$store.state.street"></street>
			<peopleone v-if="$store.state.peopleone"></peopleone>
			<peopletwo v-if="$store.state.peopletwo"></peopletwo>
			<peoplethree v-if="$store.state.peoplethree"></peoplethree>
			<stadiumone v-if="$store.state.stadiumone"></stadiumone>
			<stadiumtwo v-if="$store.state.stadiumtwo"></stadiumtwo>
			<stadiumthree v-if="$store.state.stadiumthree"></stadiumthree>
			<touristwalk v-if="$store.state.touristwalk"></touristwalk>
			<familyfitness v-if="$store.state.familyfitness"></familyfitness>
			<eventsone v-if="$store.state.eventsone"></eventsone>
			<sportindustry v-if="$store.state.sportindustry"></sportindustry>
			<association v-if="$store.state.association"></association>
			<socialinstructor v-if="$store.state.socialinstructor"></socialinstructor>
			<athlete v-if="$store.state.athlete"></athlete>
			<nationalfitness v-if="$store.state.nationalfitness"></nationalfitness>
			<outdoorpain v-if="$store.state.outdoorpain"></outdoorpain>
			<streetone v-if="$store.state.streetone"></streetone>
		</div> -->
      <router-view></router-view>
  </div>
</template>
<script>
// import lotteryjson from '../public/data/lottery.json'
// import {config,upDataMap,ajaxRequest} from './assets/js/config.js'
// import {amap} from './assets/js/amap.js'
// import { show } from './assets/js/animate.js'
// import {getAjax, timestamp} from "./assets/js/websocket";
// window.lotteryjson = lotteryjson;
// import bus from "./assets/js/bus";
export default {
  // components:{
  // 	shaoxingone: () => import('./components/home/shaoxingone.vue'),			// 绍兴市数字化治理智慧平台
  // 	street: () => import('./components/home/street.vue'),				// 稽山街道体育产业智慧平台—越城区
  // 	peopleone: () => import('./components/home/peopleone.vue'),				// 百姓健身房
  // 	peopletwo: () => import('./components/home/peopletwo.vue'),				// 百姓健身房—健身房大屏展现版本
  // 	peoplethree: () => import('./components/home/peoplethree.vue'),			// 百姓健身房—健身房
  // 	stadiumone: () => import('./components/home/stadiumone.vue'),				// 场馆
  // 	stadiumtwo: () => import('./components/home/stadiumtwo.vue'),				// 场馆1
  // 	stadiumthree: () => import('./components/home/stadiumthree.vue'),			// 场馆—建设中
  // 	touristwalk: () => import('./components/home/touristwalk.vue'),			// 游步道
  // 	familyfitness: () => import('./components/home/familyfitness.vue'),			// 家庭健身
  // 	eventsone: () => import('./components/home/eventsone.vue'),				// 赛事活动
  // 	sportindustry: () => import('./components/home/sportindustry.vue'),			// 体育产业
  // 	association: () => import('./components/home/association.vue'),			// 协会
  // 	socialinstructor: () => import('./components/home/socialinstructor.vue'),		// 社会指导员
  // 	athlete: () => import('./components/home/athlete.vue'),				// 运动员
  // 	nationalfitness: () => import('./components/home/nationalfitness.vue'),		// 全明健身发展指数
  // 	outdoorpain: () => import('./components/home/outdoorpain.vue'),			// 户外运动排名
  // 	streetone: () => import('./components/home/streetone.vue'),			// 稽山街道
  // },
  data() {
    return {
      // // 标题
      // title: window.PageTitle,
      // // 标题时间
      // titleTime: "",
      // // 标题日期
      // titleDate: "",
      // // 标题周
      // titleWeek: ""
    };
  },
  methods: {
    // initAmap() {
    // 	window.initAmap();
    // },
    // windowEchartsBtnClick(event){
    // 	window.echartsBtnClick(event);
    // },
    // leftclick(e, showdata) {
    // 	let _this = this
    // 	$(".appnav1").attr("class","appnav1")
    // 	$(".appnav2").attr("class","appnav2")
    // 	if(e.target.innerText === "主页"){
    // 		this.plug.upDataMap(lotteryjson.home)
    // 		show(showdata, _this)
    // 		$('#amap').show();
    // 		$('#main-border').show();
    // 		e.currentTarget.className = "appnav1 selected"
    // 	}else if(e.target.innerText === "百姓健身房"){
    // 		let that = this;
    // 		let url = window.appHost+"/api/v1/screen/Venue/onlinelist"
    // 		let result = window.ajaxRequest(url,JSON.stringify(data),"post")
    // 		// console.log(result)
    // 		lotteryjson.people.data.center = [parseFloat(result.data[0].lng),parseFloat(result.data[0].lat)]
    // 		lotteryjson.people.data.points = [];
    // 		result.data.forEach((item,index)=>{
    // 			if(item.lng > 0){
    // 				lotteryjson.people.data.points.push({
    // 					location:[parseFloat(item.lng),parseFloat(item.lat)],
    // 					name:item.title,
    // 					id:item.id
    // 				})
    // 			}
    // 		})
    // 		console.log(lotteryjson.people)
    // 		that.plug.upDataMap(lotteryjson.people)
    // 		show(showdata, _this)
    // 		$('#amap').show();
    // 		$('#main-border').show();
    // 		e.currentTarget.className = "appnav1 selected"
    // 	}else if(e.target.innerText === "体育场馆"){
    // 		this.plug.upDataMap(lotteryjson.gymnasium)
    // 		show(showdata, _this)
    // 		$('#amap').show();
    // 		$('#main-border').hide();
    // 		e.currentTarget.className = "appnav1 selected"
    // 	}else if(e.target.innerText === "游步道"){
    //         // console.log(lotteryjson.taohuadao)
    //         var data = {
    //             token: window.getToken(),
    //             app_id: window.appId,
    //             time: timestamp(),
    //         }
    //         getAjax({
    //             url: "/map/screen/getFootPath",
    //             method:"GET",
    //             data:data,
    //             success:function(res){
    //                 if(res.result){
    //                     lotteryjson.taohuadao.data.center = [res.data[0].lng, res.data[0].lat]
    //                     lotteryjson.taohuadao.data.points = JSON.parse(res.data[0].lines)
    // 					// console.log(lotteryjson.taohuadao)
    //                     // _this.plug.upDataMap(lotteryjson.taohuadao)
    //                     _this.plug.upDataMap(lotteryjson.taohuadao)
    // 					bus.$emit('talkId',res.data[0].id)
    //                 }else{
    //                     _this.plug.upDataMap(lotteryjson.taohuadao)
    //                 }
    //             },
    //         })
    // 		show(showdata, _this)
    // 		$('#amap').show();
    // 		$('#main-border').show();
    // 		e.currentTarget.className = "appnav1 selected"
    // 	}
    // },
    // rightclick(e, showdata){
    // 	let _this = this
    // 	$(".appnav1").attr("class","appnav1")
    // 	$(".appnav2").attr("class","appnav2")
    // 	if(e.target.innerText === "云健身房"){
    // 		show(showdata, _this)
    // 		$('#amap').hide();
    // 		$('#main-border').show();
    // 		e.currentTarget.className = "appnav2 selected"
    // 	}else if(e.target.innerText === "赛事"){
    // 		var data = {
    //             token: window.getToken(),
    //             app_id: window.appId,
    //             time: timestamp(),
    //         }
    // 		getAjax({
    //             url: "/map/screen/getMatchAll?ranking=总",
    //             method:"GET",
    //             data:data,
    //             success:function(res){
    //                 console.log(res)
    // 				setTimeout(() => {
    // 					bus.$emit('match_images',res.data.match_images);
    // 					bus.$emit('match_list',res.data.match_list.data);
    // 					bus.$emit('portrait',{
    // 						man_woman:res.data.men_women_proportion,
    // 						age:res.data.old
    // 					});
    // 					bus.$emit('num_ranking',res.data.area_data);
    // 					bus.$emit('all_num',{
    // 						match_all : res.data.match_list.total,
    // 						player_all : res.data.match_user_total
    // 					});
    // 					bus.$emit('time_analyse',res.data.to_last_month);
    // 				}, 500);
    //             },
    //         })
    // 		show(showdata, _this)
    // 		$('#amap').hide();
    // 		$('#main-border').show();
    // 		e.currentTarget.className = "appnav2 selected"
    // 	}else if(e.target.innerText === "体育产业"){
    // 		var data = {
    //             token: window.getToken(),
    //             app_id: window.appId,
    //             time: timestamp(),
    //         }
    //         getAjax({
    //             url: "/map/screen/getSportsBusiness",
    //             method:"GET",
    //             data:data,
    //             success:function(res){
    //                 // console.log(res)
    // 				if(res.result){
    // 					bus.$emit('enterpriseInfo',res.data.sports_business_main);
    // 					bus.$emit('enterpriseType',res.data.type_count);
    // 					bus.$emit('goods',res.data.goods);
    // 					// bus.$emit('lotterySales',res.data.sports_lottery_sales);
    // 					bus.$emit('lottery_sales',res.data.area_data);
    // 					lotteryjson.lottery.data.center = [res.data.sports_lottery_list[0].lng,res.data.sports_lottery_list[0].lat]
    // 					lotteryjson.lottery.data.points = [];
    // 					res.data.sports_lottery_list.forEach(item=>{
    // 						lotteryjson.lottery.data.points.push({
    // 							location:[item.lng, item.lat],
    // 							name: item.title
    // 						})
    // 						// console.log(item)
    // 					})
    // 					_this.plug.upDataMap(lotteryjson.lottery)
    // 				}
    //             },
    //         })
    // 		// let url = window.appHost+"/api/v1/screen/Lottery/listitem"
    // 		// let result = window.ajaxRequest(url,JSON.stringify(data))
    // 		// console.log(result)
    // 		// if(result.code == 1){
    // 		// 	lotteryjson.lottery.data.center = [parseFloat(result.data[0].lng),parseFloat(result.data[0].lat)]
    // 		// 	lotteryjson.lottery.data.points = [];
    // 		// 	result.data.forEach(item=>{
    // 		// 		lotteryjson.lottery.data.points.push({
    // 		// 			location:[item.lng, item.lat],
    // 		// 			name: item.title
    // 		// 		})
    // 		// 		// console.log(item)
    // 		// 	})
    // 		// }
    // 		show(showdata, _this)
    // 		$('#amap').show();
    // 		$('#main-border').show();
    // 		e.currentTarget.className = "appnav2 selected"
    // 	}else if(e.target.innerText === "体育协会"){
    // 		var data = {
    //             token: window.getToken(),
    //             app_id: window.appId,
    //             time: timestamp(),
    //         }
    // 		getAjax({
    //             url: "/map/screen/getSportsAssociationAll",
    //             method:"GET",
    //             data:data,
    //             success:function(res){
    //                 // console.log(res)
    //             },
    //         })
    // 		show(showdata, _this)
    // 		$('#amap').hide();
    // 		$('#main-border').show();
    // 		e.currentTarget.className = "appnav2 selected"
    // 	}
    // },
    // fullScreenClick(event) {
    // 	window.fullScreenClick(event)
    // },
    // // 自适应
    // resize() {
    // 	window.windowResize()
    // },
    // // 标题栏时间
    // getDate() {
    // 	var that = this;
    // 	setInterval(function() {
    // 		var shwoDate = window.showDate();
    // 		that.titleTime = shwoDate.titleTime
    // 		that.titleWeek = shwoDate.titleWeek
    // 		that.titleDate = shwoDate.titleDate
    // 	}, 1000)
    // },
  },
  mounted() {
    // window.thisShow = this;
    // var that = this;
    window.initLoading();
    // that.resize();
    // that.initAmap();
    // that.getDate();
  },
};
</script>
<style scoped rel="stylesheet/less">
@import url("./assets/css/index.css");
</style>
