<template>
	<div>
		<div id="map"></div>
	</div>
</template>

<script>
	export default {
		data () {
			return {
				
			}
		},
		methods: {
			initMap(){
				/* var map = new AMap.Map('map', {
					viewMode: '3D', //设置3D地图
					zoom: 12, //地图层级
					//rotation: -35, //默认地图旋转角度
					pitch: 10,//地图仰角
					// center: [121.304018, 31.217688],
					center: [120.428985,30.021547],
					mapStyle: 'amap://styles/f79f2bec89e18b5e5d2674f66613a81b', //设置地图
					showBuildingBlock: false, // 显示全局楼宇
					showLabel: true,//显示全局名称
					resizeEnable: true ,//全局设置尺寸
					skyColor: '#0031a0',//天空颜色
				});
				
				
					
				$(document).on("mousewheel DOMMouseScroll", function (event) {
				    
				    var delta = (event.originalEvent.wheelDelta && (event.originalEvent.wheelDelta > 0 ? 1 : -1)) ||  // chrome & ie
				                (event.originalEvent.detail && (event.originalEvent.detail > 0 ? -1 : 1));              // firefox
					var getZoom = ''+map.getZoom();
					var getCenter = ''+map.getCenter().toString();
				    if (delta > 0) {
				    // 向上滚
					 $("#getZoom").text(getZoom);
					 $("#getCenter").text(getCenter);
				    //do somthing
				    }
					if (delta < 0) {
				      // 向下滚
					  $("#getZoom").text(getZoom);
					  $("#getCenter").text(getCenter);
				      console.log('向下滚',getZoom)//获取级别
				      console.log(getCenter)//获取中心点
				     //do somthing
				    }
					
				
				}); */
			}
		},
		mounted () {
			//that = this;
			//that.initMap();
		},
	}
</script>

<style scoped>
	html,
	body,
	#map {
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		background:#000
	}
	
	.demo-title {
		position: absolute;
		top: 25px;
		left: 25px;
		z-index: 1;
		background: #000000de;
		padding: 20px;
		border: 1px solid #444;
	}
	
	h1 {
		font-size: 18px;
		margin: 0;
		color: rgb(180, 180, 190);
	}
	
	h3 {
		font-size: 12px;
		font-weight: normal;
		margin-top: 5px;
		color: rgb(150, 150, 150);
	}
	
	.start-btn {
		position: absolute;
		bottom: 20px;
		right: 20px;
		padding: 0 18px;
		height: 30px;
		background-color: #1A66FF;
		border-radius: 5px;
		z-index: 1;
		cursor: pointer;
	}
	.start-move {
		position: absolute;
		bottom: 20px;
		right: 150px;
		padding: 0 18px;
		height: 30px;
		background-color: #1A66FF;
		border-radius: 5px;
		z-index: 1;
		cursor: pointer;
	}
	
	.start-btn>a {
		color: #fff;
		display: block;
		height: 100%;
		line-height: 30px;
		text-align: center;
		font-size: 14px;
	}
	.start-move>a{
		color: #fff;
		display: block;
		height: 100%;
		line-height: 30px;
		text-align: center;
		font-size: 14px;
	}
	#getZoom,#getCenter{
		color:greenyellow;
		font-weight: bold;
	}
</style>